<template>
    <BaseSection
        v-if="allEntries.length > 0"
        class="card-slider"
        :width="props.width"
    >
        <template
            v-if="title"
            #title
        >
            {{ title }}
        </template>

        <BaseSlider :items="allEntries" v-bind="$attrs" class="card-slider__slider">
            <!-- Pass down whatever slots are given to this component-->
            <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope || {}" />
            </template>

            <template v-slot:slide="{ item, isVisible }">
                <BaseCard
                    v-if="item && item?.type !== 'readmore'"
                    :featured-entry="item"
                    :is-visible="isVisible"
                    drag-to-scroll="ignore"
                    draggable="false"
                />

                <ReadMoreButton v-else-if="featuredEntries.length > 4" :link="item" class="read-more-button">
                    <div class="read-more-button__inner">
                        {{ $t('read-more') }}
                    </div>
                </ReadMoreButton>
            </template>
        </BaseSlider>
    </BaseSection>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    // Should this prop ever change, please update TheMenuOverlaySlider accordingly
    featuredEntries: {
        type: Array,
        required: true,
        default: () => []
    },
    readmoreEntry: {
        type: Object,
        required: false,
        default: () => {}
    },
    width: {
        type: String,
        default: 'bleed'
    }
});

const allEntries = computed(() => {
    const futureEntries = props.featuredEntries.filter((entry) => {
        if (entry.dateRange) {
            return entry.dateRange.isNotPast;
        }
        return true;
    });

    if (futureEntries.length > 0 && readMore.value) {
        return [...futureEntries, readMore];
    }
    return futureEntries;
});

const readMore = computed(() => {
    if (props.readmoreEntry) {
        return { ...props.readmoreEntry[0], type: 'readmore' };
    }
    return null;
});
</script>

<style lang="less">
.card-slider {}

.card-slider__slider .read-more-button {
    align-self: center;
    margin: 0 1.25rem;
}

.card-slider .section__title {
    margin-bottom: 1.5rem;

    @media @q-md-min {
        margin-bottom: 2rem;
    }
}
</style>
